(function($) {
	'use strict'; 
	window.maisonmatWP = {
		cache: {
			$document: $(document),
			$window: $(window)
		}, 
		init: function() {
			this.bindEvents();
		},
		bindEvents: function() { 
			var self = this;
			$(window).ready(function() {
				if($('body').hasClass('isdesktop')){
				}
				
				
				
				$(window).resize(function() {
					self.handleResize();
				});
				
				
			});
		}, 
		handleResize: function() {
			var window_width = $(window).width();
		},

	}
	maisonmatWP.init();
})(jQuery);